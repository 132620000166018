@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

/* -----------------------------------
// COLORES FONDO BÁSICOS
--------------------------------------- */

html {
  background: rgb(0, 0, 0);
  max-width: 100%;
  overflow-x: hidden;
}

main {
  background-color: rgba(0, 0, 0, 1);
  padding-top: 7rem;
  padding-bottom: 5rem;
}

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  scrollbar-color: rgb(173, 173, 15) yellow;
  scrollbar-arrow-color: black;
}

/* -----------------------------------
// TARJETAS DE INFORMACIÓN
--------------------------------------- */

.ventas {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
  background-color: cornflowerblue;
}

.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: 100vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: #f8f9fd;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin: 10px;
}
.Header_main_header__1U-DA {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0, 0, 0, 0.5);
  padding: 0 2rem;
  color:white;
}
  
.Header_main_header__1U-DA h1 {
  color: white;
}
  
.Header_nav__K0wQp ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
  
.Header_nav__K0wQp li {
  margin: 0;
  margin-left: 2rem;
  color:rgb(255, 255, 255);
}

.Header_nav__K0wQp a {
  color: white;
  text-decoration: none;
}
  
.Header_nav__K0wQp a:hover,
.Header_nav__K0wQp a:active {
  color: #ffffff;
}

.Header_img_header__2leQG {
  width: 7%;
}

.Header_navBackground__3-jE- {
  background-color: #000000;
}
.DropdownWallet_dropMenu__2O3c8 {
    color: black !important;
}

.DropdownWallet_buttonInvisible__gNF9C {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.Card_card__2SSyi {
    box-shadow: 0 0 10px rgba(223, 223, 223, 0.26);
    border-radius: 30px;
    /*background: white;*/
}

.Card_cardContainer__1-HBd {
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    color:white;
    display: inline-block;
}

.Card_cardImg__cpgNA {
    width: 90%;
    max-width: 35rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 0px !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
    cursor: url('https://i.imgur.com/8KeJTzH.png'), pointer;
}

.Card_cardLoading__34qIK {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    border-radius: 50px !important;
}

.Card_cardInfo__2C6Fw {
    width: 90%;
    max-width: 35rem;
    padding: 1rem;
    margin: auto;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    border-radius: 0px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.Card_centrador__2NzQh {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

ul {
    justify-content: center !important;
}

.Card_selector__2MhVf {
    -webkit-filter: invert(1);
            filter: invert(1);
    margin-top: 2rem;
}

.Card_title_1__1jeac {
    font-size: 0.3rem;
}

.Card_title_2__2ygo_ {
    font-size: 0.45rem;
}

.Card_title_3__11_tH {
    font-size: 0.6rem;
}

.Card_title_4__3F3aF {
    font-size: 0.75rem;
}

.Card_title_5__3qBPL {
    font-size: 0.9rem;
}

.Card_title_6__1z7G6 {
    font-size: 1.05rem;
}

.Card_title_7__2GxFL {
    font-size: 1.2rem;
}

.Card_title_8__2rmLa {
    font-size: 1.35rem;
}

.Card_title_9__16tDj {
    font-size: 1.5rem;
}

.Card_title_10__1if40 {
    font-size: 1.65rem;
}
.NoConectado_info__3en_A {
    width: 75%;
    padding: 3rem;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    color:white;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.NoConectado_espaciador__1Phv- {
    padding-bottom: 5%;
}

.NoConectado_divVideoInfo__1Z57o {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin:auto;
    /*pointer-events: none;*/
}

.NoConectado_videoInfo__jatiq {
    width:100%;
    height:100%;
    position:absolute;
    left:0px;
    top:0px;
    overflow:hidden;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /*pointer-events: none;*/
}

.CountdownSale_countStyle__175NS {
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    margin: auto;
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
}
.Admin_infoAdmin__Ag0us {
    width: 30%;
    padding: 1rem 1rem;
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.3);
    color:white;
    display: inline-block;
    margin-right: 25px;
}

.Admin_infoTransactions__3MtzE {
    width: 50%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.Admin_infoAdminTransactions__1bacu {
    width: 80%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.Admin_centrador__2LblQ {
    position: inherit;
    text-align: center;
    margin: auto;
}

.Admin_jsonTable__DQeek table{
    table-layout: fixed;
}

.Admin_jsonTable__DQeek tr,th,td {
    background-color: rgb(83, 99, 99) !important;
    color: white !important;
}

.Admin_jsonTable__DQeek tbody {
    display: block;
    overflow: auto;
}
.Error404_infoCapsules__1SNVM {
    width: 90%;
    max-width: 60rem;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.Error404_infoCapsulesCard__1kkXH {
    width: 90%;
    max-width: 40rem;
    padding: 3rem;
    margin: 2rem auto;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}

.Error404_centrador__2LhZe {
    position: inherit;
    
    height: 100%;
    text-align: center;
    margin: auto;
}

.Error404_imgError__8HA4x{
    width: 90%;
    -webkit-filter: brightness(1);
            filter: brightness(1);
}
.CenterLogo_img_logo__1lOFM {
    width: 50%;
    height: auto;
    text-align: center;
    padding: 3rem;
}

.CenterLogo_centrador__3HVKM{
    max-width: 500px;
    margin: auto;
}
  
.CenterLogo_espaciador__1UK74 {
    padding-bottom: 2rem;
}

.Footer_imgfooter__3NrdT {
    height: 100px;
}

.Footer_fooBackground__1Z9y0 {
    background-color: rgba(0, 0, 0, 1);
}
.Loader_loaderDiv__1ACdt{
    position: absolute;
    padding-bottom: 15%;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: black;
}

.Loader_centrador__29B40 {
    margin: auto;
    padding-bottom: 10%;
}

.Loader_loader__5PsR_ {
    position:relative;
      width:30px;
      height:20px;
  
      -webkit-animation:Loader_infinite-spinning__3xKG4 4s infinite;
  
              animation:Loader_infinite-spinning__3xKG4 4s infinite;
      -webkit-animation-timing-function:linear;
              animation-timing-function:linear;
      -webkit-transform-origin:335% 500%;
              transform-origin:335% 500%;
  }
  
  @-webkit-keyframes Loader_infinite-spinning__3xKG4 {
      from {
          -webkit-transform:rotate(0deg);
                  transform:rotate(0deg);
      }
      to {
          -webkit-transform:rotate(360deg);
                  transform:rotate(360deg);
      }
  }
  
  @keyframes Loader_infinite-spinning__3xKG4 {
      from {
          -webkit-transform:rotate(0deg);
                  transform:rotate(0deg);
      }
      to {
          -webkit-transform:rotate(360deg);
                  transform:rotate(360deg);
      }
  }
  
  .Loader_moon__1Calf {
    position: relative;
    margin: auto;
    width: 200px;
    height: 200px;
    background: #c7cbd0;
    border-radius: 50%;
    box-shadow: inset -25px 0px 0 0px #9098a1;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  
  .Loader_moon__1Calf:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }


  li.Loader_listyle__387y0 {
    position: absolute;
    list-style: none;
    background: #737277;
    border-radius: 50%;
  }
  li:nth-child(1).Loader_listyle__387y0 {
    left: 25px;
    top: 60px;
    width: 50px;
    height: 50px;
    box-shadow: inset 6px -2px 0 0px #414043;
  }
  li:nth-child(2).Loader_listyle__387y0 {
    left: 150px;
    top: 50px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(3).Loader_listyle__387y0 {
    left: 100px;
    top: 150px;
    width: 25px;
    height: 25px;
    box-shadow: inset 3px -1px 0 0px #414043;
  }
  
  li:nth-child(4).Loader_listyle__387y0 {
    left: 50px;
    top: 150px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(5).Loader_listyle__387y0 {
    left: 87.5px;
    top: 16.66666667px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(6).Loader_listyle__387y0 {
    left: 114.28571429px;
    top: 80px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  
  li:nth-child(7).Loader_listyle__387y0 {
    left: 181.81818182px;
    top: 100px;
    width: 12.5px;
    height: 12.5px;
    box-shadow: inset 2.4px -0.8px 0 0px #414043;
  }
  

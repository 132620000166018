.img_logo {
    width: 50%;
    height: auto;
    text-align: center;
    padding: 3rem;
}

.centrador{
    max-width: 500px;
    margin: auto;
}
  
.espaciador {
    padding-bottom: 2rem;
}

.dropMenu {
    color: black !important;
}

.buttonInvisible {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.infoAdmin {
    width: 30%;
    padding: 1rem 1rem;
    margin: 1rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.3);
    color:white;
    display: inline-block;
    margin-right: 25px;
}

.infoTransactions {
    width: 50%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.infoAdminTransactions {
    width: 80%;
    padding: 3rem 3rem;
    margin: 2rem auto;
    text-align: center;
    background-color:rgba(0, 0, 0, 0.5);
    color:white;
    display: inline-block;
    margin-right: 50px;
}

.centrador {
    position: inherit;
    text-align: center;
    margin: auto;
}

.jsonTable table{
    table-layout: fixed;
}

.jsonTable tr,th,td {
    background-color: rgb(83, 99, 99) !important;
    color: white !important;
}

.jsonTable tbody {
    display: block;
    overflow: auto;
}
.main_header {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0, 0, 0, 0.5);
  padding: 0 2rem;
  color:white;
}
  
.main_header h1 {
  color: white;
}
  
.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
  
.nav li {
  margin: 0;
  margin-left: 2rem;
  color:rgb(255, 255, 255);
}

.nav a {
  color: white;
  text-decoration: none;
}
  
.nav a:hover,
.nav a:active {
  color: #ffffff;
}

.img_header {
  width: 7%;
}

.navBackground {
  background-color: #000000;
}